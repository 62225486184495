import Select from 'react-select'
import {
  CardSubtitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, FormGroup } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { WithContext as ReactTags } from 'react-tag-input'

import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'
import MetaTag from 'components/Common/Meta-tag'
import { checkPageTitle, createPages, getAllProducts } from 'store/actions'
import UseImageUploader from 'components/imageUploader/useImageUploader'
import LanguageTab from 'components/Common/LanguageTab'
import { LanguageSwitch } from 'hooks/LanguageSwitch'

const CratePages = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, products, proLoading, pageTitleCheck } = useSelector(
    state => ({
      loading: state.Group.loading,
      products: state.Products.products,
      proLoading: state.Products.loading,
      pageTitleCheck: state.Pages?.pageTitleCheck,
    }),
  )

  const isMult = LanguageSwitch()?.isMult

  //product///
  const [pageImage, setPageImage] = useState([])
  const [keywords, setKeywords] = useState([])
  const [tags, setTags] = useState([])
  const [keywordsAr, setKeywordsAr] = useState([])
  const [tagsAr, setTagsAr] = useState([])
  const [checkPageTitles, setCheckPageTitles] = useState()
  const [checkPageTitlesAr, setCheckPageTitlesAr] = useState()
  const [activeTab, setActiveTab] = useState('1')

  const [productSelect, setproductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setproductSelect(product)
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result.hasVarients
              ? result?.varients?.length >= 1
                ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
                : ''
              : ''
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        }),
      ),
    },
  ]

  useEffect(() => {
    dispatch(getAllProducts(1, 'all', '', productSearch))
  }, [dispatch, productSearch])

  const handleValidSubmit = value => {
    const pageData = {
      ...value,
      product: productSelect?.id,
      primaryLang: {
        ...value?.primaryLang,
        keywords: keywords,
      },
      secondaryLang: {
        ...value?.secondaryLang,
        keywords: keywordsAr,
      },
      // keywords: keywords,
      // description: value.description,
      images: pageImage,
    }
    dispatch(createPages(pageData, history))
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked')
  }

  const KeyCodesAr = {
    comma: 188,
    enter: 13,
  }

  const delimitersAr = [KeyCodesAr.comma, KeyCodesAr.enter]

  const handleDeleteTagAr = i => {
    setTagsAr(tagsAr.filter((tag, index) => index !== i))
    const arrangedKeywords = tagsAr.map(a => a.text)
    setKeywordsAr(arrangedKeywords)
  }

  const handleAdditionAr = tag => {
    setTagsAr([...tagsAr, tag])
    const arrangedKeywords = [...tagsAr, tag].map(a => a.text)
    setKeywordsAr(arrangedKeywords)
  }

  const handleDragAr = (tag, currPos, newPos) => {
    const newTags = tagsAr.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTagsAr(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywordsAr(arrangedKeywords)
  }

  const handleTagClickAr = index => {
    console.log('The tag at index ' + index + ' was clicked')
  }

  return (
    <>
      <MetaTag title={'Create Pages'} />

      <div className="page-content">
        <Breadcrumbs title="Pages" breadcrumbItem="Create Page" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="8">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <div className="col-12 mb-3">
                            <AvField
                              name="primaryLang.title"
                              className=""
                              label="Page Title"
                              type="text"
                              onChange={e => {
                                setCheckPageTitles(e.target.value)
                                dispatch(checkPageTitle(e.target.value))
                              }}
                            />
                            {checkPageTitles && (
                              <>
                                {pageTitleCheck?.response === true ? (
                                  <h6
                                    className="pt-1"
                                    style={{ color: 'green' }}
                                  >
                                    {' '}
                                    Available
                                  </h6>
                                ) : (
                                  <h6 className="pt-1" style={{ color: 'red' }}>
                                    Not Available{' '}
                                  </h6>
                                )}
                              </>
                            )}
                          </div>{' '}
                          <div className="col-12 mb-3">
                            <AvField
                              style={{ height: 100 }}
                              name="primaryLang.description"
                              className=""
                              label="Description"
                              type="textarea"
                            />
                          </div>
                          <div className=" col-12 pb-3">
                            <label>Keywords</label>
                            <ReactTags
                              tags={tags}
                              placeholder="Type TEXT and enter"
                              // suggestions={suggestions}
                              delimiters={delimiters}
                              handleDelete={handleDeleteTag}
                              handleAddition={handleAddition}
                              handleDrag={handleDrag}
                              handleTagClick={handleTagClick}
                              // inputFieldPosition="inline"
                              autocomplete
                              allowUnique
                              inline
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className="col-12 mb-3">
                            <div className="d-flex justify-content-end mb-2">
                              <CardSubtitle>عنوان</CardSubtitle>
                            </div>
                            <AvField
                              style={{ textAlign: 'right' }}
                              name="secondaryLang.title"
                              className=""
                              label=""
                              type="text"
                              onChange={e => {
                                setCheckPageTitlesAr(e.target.value)
                                dispatch(checkPageTitle(e.target.value))
                              }}
                            />
                            {checkPageTitlesAr && (
                              <>
                                {pageTitleCheck?.response === true ? (
                                  <h6
                                    className="pt-1"
                                    style={{ color: 'green', textAlign: 'end' }}
                                  >
                                    {' '}
                                    متاح
                                  </h6>
                                ) : (
                                  <h6
                                    className="pt-1"
                                    style={{ color: 'red', textAlign: 'end' }}
                                  >
                                    غير متاح{' '}
                                  </h6>
                                )}
                              </>
                            )}
                          </div>{' '}
                          <div className="col-12 mb-3">
                            <div className="d-flex justify-content-end mb-2">
                              <CardSubtitle>وصف</CardSubtitle>
                            </div>
                            <AvField
                              style={{ height: 100, textAlign: 'right' }}
                              name="secondaryLang.description"
                              className=""
                              label=""
                              type="textarea"
                            />
                          </div>
                          <div className="col-12 pb-3 d-flex justify-content-end">
                            <div className="tag-arabic">
                              <label className="d-flex justify-content-end">
                                الكلمات الدالة
                              </label>
                              <ReactTags
                                tags={tagsAr}
                                placeholder="اكتب النص وأدخل"
                                // suggestions={suggestions}
                                delimiters={delimitersAr}
                                handleDelete={handleDeleteTagAr}
                                handleAddition={handleAdditionAr}
                                handleDrag={handleDragAr}
                                handleTagClick={handleTagClickAr}
                                // inputFieldPosition="inline"
                                autocomplete
                                allowUnique
                                inline
                              />
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                      <Col className="col-12 d-flex flex-wrap">
                        <div className="col-12 mb-3">
                          <CardTitle className="h4 mb-2 text_deals_green ">
                            Select Product
                          </CardTitle>
                          <FormGroup className="mb-3">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleProductEnters}
                                value={productSelect?.title}
                                placeholder={productSelect?.title}
                                onChange={handlerProductFinalValue}
                                options={productOptions}
                                classNamePrefix="select2-selection"
                                isLoading={proLoading}
                              />
                            </div>
                          </FormGroup>
                        </div>
                        {/* <div className=" col-12 pb-3">
                          <label>Keywords</label>
                          <ReactTags
                            tags={tags}
                            placeholder="Type TEXT and enter"
                            // suggestions={suggestions}
                            delimiters={delimiters}
                            handleDelete={handleDeleteTag}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            // inputFieldPosition="inline"
                            autocomplete
                            allowUnique
                            inline
                          />
                        </div> */}
                        <div className=" col-12 pb-2">
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={pageImage}
                            setImagesPreview={setPageImage}
                            uploadUrl={'/pages'}
                          />
                        </div>
                      </Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="col-12 btn btn-success save-customer"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Create Page
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CratePages
