import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'
import { Row, TabContent, TabPane, Container } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'

import Breadcrumbs from 'components/Common/Breadcrumb'
import Switch from 'react-switch'
import UseImageUploader from 'components/imageUploader/useImageUploader'

//actions
import { createCategory, getAllClassifications } from 'store/actions'
import LanguageTab from 'components/Common/LanguageTab'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import MetaTag from 'components/Common/Meta-tag'
import { debounce } from 'lodash'

const CreateCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { loading, categories, catLoading } = useSelector(state => ({
    loading: state.Category.loading,
    categories: state.Classifications.classifications,
    catLoading: state.Classifications.loading,
  }))

  const [categorySelect, setCategorySelect] = useState({
    id: '',
    title: 'Search a Category',
  })

  const [categorySearch, setCategorySearch] = useState()

  const [logoImagesPreview, setLogoImagesPreview] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])

  const [activeTab, setActiveTab] = useState('1')
  const [isMain, setIsMain] = useState(false)

  useEffect(() => {
    // dispatch(getCategories(1, "", categorySearch))
    dispatch(getAllClassifications('', 'all', '', categorySearch))
  }, [dispatch, categorySearch])

  function handleValidSubmit(values) {
    if (categorySelect?.id) {
      const createCategoryData = {
        primaryLang: values.primaryLang,
        secondaryLang: values.secondaryLang,
        priority: parseInt(values.priority),
        isMain,
        mainCategory: categorySelect?.id ? categorySelect?.id : null,
        logoImages: logoImagesPreview,
        bannerImages: bannerImagesPreview,
      }
      dispatch(createCategory(createCategoryData, history))
    } else {
      const createCategoryData = {
        primaryLang: values.primaryLang,
        secondaryLang: values.secondaryLang,
        priority: parseInt(values.priority),
        isMain,
        logoImages: logoImagesPreview,
        bannerImages: bannerImagesPreview,
      }
      dispatch(createCategory(createCategoryData, history))
    }
  }

  const isMult = LanguageSwitch()

  console.log(isMult)

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        Yes
      </div>
    )
  }

  const debounceCategorySearch = debounce(
    value => setCategorySearch(value),
    600,
  )
  const handleCategoryEnters = textEntered => {
    debounceCategorySearch(textEntered)
  }

  function handlerCategoryFinalValue(event) {
    const category = {
      ...categorySelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCategorySelect(category)
  }

  const categoryOptions = [
    {
      options:
        categories?.length &&
        categories?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  return (
    <>
      <MetaTag title="Create Category" />

      <div className="page-content">
        <Breadcrumbs title="Categorys" breadcrumbItem="Create Category" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="6">
                          <div className="col-3 mb-3">
                            <AvField
                              name="priority"
                              label="Priority"
                              type="number"
                            />
                          </div>
                        </Col>
                        {(location?.state === 'main' ||
                          location?.state === 'sub-main') && (
                          <Col xl="6">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-4 col-form-label"
                            >
                              {' '}
                              Is Main Head
                            </Label>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={() => {
                                setIsMain(!isMain)
                              }}
                              checked={isMain || false}
                            />
                          </Col>
                        )}
                      </Row>
                      <Col className="db__column col-4">
                        <CardTitle className="h4 mb-2 text_deals_green ">
                          Category
                        </CardTitle>
                        <FormGroup className="mb-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleCategoryEnters}
                              value={categorySelect?.title}
                              placeholder={categorySelect?.title}
                              onChange={handlerCategoryFinalValue}
                              options={categoryOptions}
                              classNamePrefix="select2-selection"
                              isLoading={catLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>

                {/* lang */}
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}

                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.name"
                                className=""
                                label="Name"
                                type="text"
                                errorMessage="Invalid product name"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <AvField
                                name="primaryLang.description"
                                label="Description"
                                type="textarea"
                                errorMessage="Invalid description"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="2">
                          <Col className="col-12 d-flex flex-wrap">
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-end mb-2">
                                {/* <CardSubtitle>Name</CardSubtitle> */}
                                <CardSubtitle>اسم</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.name"
                                label=""
                                type="text"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <div className="d-flex justify-content-end mb-2">
                                {/* <CardSubtitle>Description</CardSubtitle> */}
                                <CardSubtitle>وصف</CardSubtitle>
                              </div>
                              <AvField
                                style={{ textAlign: 'right' }}
                                name="secondaryLang.description"
                                label=""
                                type="textarea"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-12">
                  {/* <ImageCard
                    onDeleteImg={onDeleteImg}
                    bannerImagesPreview={bannerImagesPreview}
                    logoImagesPreview={logoImagesPreview}
                    onChangeBannerImageHandler={onChangeBannerImageHandler}
                    onChangeLogoImageHandler={onChangeLogoImageHandler}
                  /> */}
                </Col>
                <div>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <Label className="mb-2">Logo</Label>
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={logoImagesPreview}
                            setImagesPreview={setLogoImagesPreview}
                            uploadUrl={'/categories'}
                          />
                        </Col>
                        <Col md="6">
                          <Label className="mb-2">Banner</Label>
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={bannerImagesPreview}
                            setImagesPreview={setBannerImagesPreview}
                            uploadUrl={'/categories'}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Cateory
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCategory
