/* PRODUCTS */
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'

/* ALL PRODUCTS */
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAIL = 'GET_ALL_PRODUCTS_FAIL'

// product sort count

export const GET_PRODUCT_SORT_COUNT = 'GET_PRODUCT_SORT_COUNT'
export const GET_PRODUCT_SORT_COUNT_SUCCESS = 'GET_PRODUCT_SORT_COUNT_SUCCESS'
export const GET_PRODUCT_SORT_COUNT_FAIL = 'GET_PRODUCT_SORT_COUNT_FAIL'

/* ALL PRODUCTS LIST*/
export const GET_ALL_PRODUCTS_LIST = 'GET_ALL_PRODUCTS_LIST'
export const GET_ALL_PRODUCTS_LIST_SUCCESS = 'GET_ALL_PRODUCTS_LIST_SUCCESS'
export const GET_ALL_PRODUCTS_LIST_FAIL = 'GET_ALL_PRODUCTS_LIST_FAIL'

/* DELETED_PRODUCTS */
export const GET_DELETED_PRODUCTS = 'GET_DELETED_PRODUCTS'
export const GET_DELETED_PRODUCTS_SUCCESS = 'GET_DELETED_PRODUCTS_SUCCESS'
export const GET_DELETED_PRODUCTS_FAIL = 'GET_DELETED_PRODUCTS_FAIL'

/* PRODUCT_DETAILS */
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS'
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS'
export const GET_PRODUCT_DETAILS_FAIL = 'GET_PRODUCT_DETAILS_FAIL'

/* CREATE PRODUCT*/
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'

/* Edit PRODUCT*/
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

/* ALL PRODUCTS FOR DOWNLOAD */
export const GET_ALL_PRODUCTS_FOR_DOWNLOAD = 'GET_ALL_PRODUCTS_FOR_DOWNLOAD'
export const GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS =
  'GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS'
export const GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL =
  'GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL'

/* Change PRODUCT status*/
export const CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS'
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS'
export const CHANGE_PRODUCT_STATUS_FAIL = 'CHANGE_PRODUCT_STATUS_FAIL'

/* Update Bulk Products*/
export const UPDATE_BULK_PRODUCTS = 'UPDATE_BULK_PRODUCTS'
export const UPDATE_BULK_PRODUCTS_SUCCESS = 'UPDATE_BULK_PRODUCTS_SUCCESS'
export const UPDATE_BULK_PRODUCTS_FAIL = 'UPDATE_BULK_PRODUCTS_FAIL'

/* Delete PRODUCT*/
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

/* Hard Delete PRODUCT*/
export const HARD_DELETE_PRODUCT = 'HARD_DELETE_PRODUCT'
export const HARD_DELETE_PRODUCT_SUCCESS = 'HARD_DELETE_PRODUCT_SUCCESS'
export const HARD_DELETE_PRODUCT_FAIL = 'HARD_DELETE_PRODUCT_FAIL'

/* Restore PRODUCT*/
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const RESTORE_PRODUCT_SUCCESS = 'RESTORE_PRODUCT_SUCCESS'
export const RESTORE_PRODUCT_FAIL = 'RESTORE_PRODUCT_FAIL'

/* Create Duplicate PRODUCT*/
export const CREATE_DUPLICATE_PRODUCT = 'CREATE_DUPLICATE_PRODUCT'
export const CREATE_DUPLICATE_PRODUCT_SUCCESS =
  'CREATE_DUPLICATE_PRODUCT_SUCCESS'
export const CREATE_DUPLICATE_PRODUCT_FAIL = 'CREATE_DUPLICATE_PRODUCT_FAIL'

export const UNDO_REFRASH = 'UNDO_REFRASH'

export const CHECK_PRODUCT_NAME = 'CHECK_PRODUCT_NAME'
export const CHECK_PRODUCT_NAME_SUCCESS = 'CHECK_PRODUCT_NAME_SUCCESS'
export const CHECK_PRODUCT_NAME_FAIL = 'CHECK_PRODUCT_NAME_FAIL'

/* review */
export const GET_REVIEWS = 'GET_REVIEWS'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

/* review details */
export const GET_REVIEW_DETAILS = 'GET_REVIEW_DETAILS'
export const GET_REVIEW_DETAILS_SUCCESS = 'GET_REVIEW_DETAILS_SUCCESS'
export const GET_REVIEW_DETAILS_FAIL = 'GET_REVIEW_DETAILS_FAIL'

/* CREATE review*/
export const CREATE_REVIEW = 'CREATE_REVIEW'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL'

/* Edit review*/
export const UPDATE_REVIEW = 'UPDATE_REVIEW'
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS'
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL'

/* delete review*/
export const DELETE_REVIEW = 'DELETE_REVIEW'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'

export const WHATSAPP_API = 'WHATSAPP_API'
export const WHATSAPP_API_SUCCESS = 'WHATSAPP_API_SUCCESS'
export const WHATSAPP_API_FAIL = 'WHATSAPP_API_FAIL'
