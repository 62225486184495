import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  FormGroup,
  Container,
  Label,
  CardTitle,
} from 'reactstrap'
import { Row, TabContent, TabPane } from 'reactstrap'
import Select from 'react-select'
import Switch from 'react-switch'
import Breadcrumbs from 'components/Common/Breadcrumb'
import UseImageUploader from 'components/imageUploader/useImageUploader'

//actions
import {
  getCategoryDetails,
  updateCategory,
  getAllClassifications,
} from 'store/actions'
import LanguageTab from 'components/Common/LanguageTab'
import { LanguageSwitch } from 'hooks/LanguageSwitch'
import MetaTag from 'components/Common/Meta-tag'
import { debounce } from 'lodash'

const UpdateCategory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    categoryDetails,
    loading,
    createCategoryError,
    categories,
    catLoading,
  } = useSelector(state => ({
    categoryDetails: state.Category.categoryDetails,
    loading: state.Category.loading,
    categories: state.Classifications.classifications,
    catLoading: state.Classifications.loading,
  }))
  const [bannerImagesPreview, setBannerImagesPreview] = useState(
    categoryDetails?.bannerImages,
  )
  const [activeTab, setActiveTab] = useState('1')

  const [logoImagesPreview, setLogoImagesPreview] = useState(
    categoryDetails?.logoImages,
  )

  const [isMain, setIsMain] = useState()

  const [categorySelect, setCategorySelect] = useState({
    id: '',
    title: 'Search a Category',
  })

  const [categorySearch, setCategorySearch] = useState()

  useEffect(() => {
    setIsMain(categoryDetails?.isMain)
  }, [categoryDetails])

  useEffect(() => {
    dispatch(getAllClassifications('', 'all', '', categorySearch))
  }, [dispatch, categorySearch])

  function handleValidSubmit(values) {
    if (categorySelect?.id) {
      const updateCategoryData = {
        primaryLang: values.primaryLang,
        secondaryLang: values.secondaryLang,
        priority: parseInt(values.priority),
        isMain,
        mainCategory: categorySelect?.id ? categorySelect?.id : null,
        logoImages: logoImagesPreview,
        bannerImages: bannerImagesPreview,
      }
      dispatch(
        updateCategory(updateCategoryData, categoryDetails?._id, history),
      )
    } else {
      const updateCategoryData = {
        primaryLang: values.primaryLang,
        secondaryLang: values.secondaryLang,
        priority: parseInt(values.priority),
        isMain,
        logoImages: logoImagesPreview,
        bannerImages: bannerImagesPreview,
      }
      dispatch(
        updateCategory(updateCategoryData, categoryDetails?._id, history),
      )
    }
  }

  useEffect(() => {
    if (params?.id) {
      dispatch(getCategoryDetails(params?.id))
    }
  }, [params?.id])

  useEffect(() => {
    setIsMain(categoryDetails?.isMain)
    setBannerImagesPreview(categoryDetails?.bannerImages)
    setLogoImagesPreview(categoryDetails?.logoImages)
  }, [categoryDetails])

  useEffect(() => {
    const category = {
      title: categoryDetails?.mainCategory?.primaryLang?.name,
      id: categoryDetails?.mainCategory?._id,
      image: categoryDetails?.mainCategory?.logoImages[0]?.url,
    }
    setCategorySelect(category)
  }, [categoryDetails])

  const isMult = LanguageSwitch()?.isMult

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        Yes
      </div>
    )
  }

  const debounceCategorySearch = debounce(
    value => setCategorySearch(value),
    600,
  )
  const handleCategoryEnters = textEntered => {
    debounceCategorySearch(textEntered)
  }

  function handlerCategoryFinalValue(event) {
    const category = {
      ...categorySelect,
      title: event.label,
      id: event.value,
      image: event.image,
    }
    setCategorySelect(category)
  }

  const categoryOptions = [
    {
      options:
        categories?.length &&
        categories?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result._id,
          image: result?.logoImages[0]?.url,
        })),
    },
  ]

  return (
    <>
      <MetaTag title={'Update Category'} />
      <div className="page-content">
        <Breadcrumbs title="Categorys" breadcrumbItem="Update Category" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  {createCategoryError && (
                    <Alert color="danger">{createCategoryError}</Alert>
                  )}
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="6">
                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              name="priority"
                              label="Priority"
                              type="number"
                              value={
                                categoryDetails?.priority
                                  ? categoryDetails?.priority
                                  : ''
                              }
                            />
                          </div>
                        </Col>
                        <Col xl="6">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-4 col-form-label"
                          >
                            {' '}
                            Is Main Head
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={() => {
                              setIsMain(!isMain)
                            }}
                            checked={isMain || false}
                          />
                        </Col>
                      </Row>
                      <Col className="db__column mx-3 col-4">
                        <CardTitle className="h4 mb-2 text_deals_green ">
                          Category
                        </CardTitle>
                        <FormGroup className="mb-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleCategoryEnters}
                              value={categorySelect?.title}
                              placeholder={categorySelect?.title}
                              onChange={handlerCategoryFinalValue}
                              options={categoryOptions}
                              classNamePrefix="select2-selection"
                              isLoading={catLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody className="mx-3">
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    className=""
                                    label="Name"
                                    type="text"
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={
                                      categoryDetails?.primaryLang?.name ?? ''
                                    }
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    value={
                                      categoryDetails?.primaryLang
                                        ?.description ?? ''
                                    }
                                  />
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col xl="12">
                              <Row>
                                <Col className="col-12 d-flex flex-wrap">
                                  <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        {/* <CardSubtitle>Name</CardSubtitle> */}
                                        <CardSubtitle>اسم</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: 'right' }}
                                        name="secondaryLang.name"
                                        label=""
                                        type="text"
                                        value={
                                          categoryDetails?.secondaryLang
                                            ?.name ?? ''
                                        }
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <div className="d-flex justify-content-between mb-2">
                                        {/* <CardSubtitle>Description</CardSubtitle> */}
                                        <CardSubtitle>وصف</CardSubtitle>
                                      </div>
                                      <AvField
                                        style={{ textAlign: 'right' }}
                                        name="secondaryLang.description"
                                        label=""
                                        type="textarea"
                                        rows="7"
                                        value={
                                          categoryDetails?.secondaryLang
                                            ?.description ?? ''
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col className="col-12">
                      <div>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="6">
                                <Label className="mb-2">Logo</Label>
                                <UseImageUploader
                                  imageSize={4}
                                  imagesPreview={logoImagesPreview}
                                  setImagesPreview={setLogoImagesPreview}
                                  uploadUrl={'/categories'}
                                />
                              </Col>
                              <Col md="6">
                                <Label className="mb-2">Banner</Label>
                                <UseImageUploader
                                  imageSize={4}
                                  imagesPreview={bannerImagesPreview}
                                  setImagesPreview={setBannerImagesPreview}
                                  uploadUrl={'/categories'}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end mb-5 mt-2">
                    <button
                      type="submit"
                      className="btn px-5 btn-success save-customer"
                    >
                      {loading && (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </>
                      )}
                      Save
                    </button>
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCategory
