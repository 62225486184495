import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  FormGroup,
  Badge,
  UncontrolledTooltip,
  // Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

// data-table related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { debounce, map, range } from 'lodash'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'

//actions
import {
  getAllChainPromoters,
  getAllSupplyChains,
  getPromoters,
} from 'store/actions'

import '../../assets/scss/data-tables.scss'

import MyPagination from 'components/Common/MyPagination'
import DeleteModal from 'components/Common/DeleteModal'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import PropTypes from 'prop-types'
import { defaultImg } from 'assets/images'

const ChainPromoters = ({ supplyChainId, promoterId, isPromoterDashboard }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const role = sessionStorage.getItem('role')

  const {
    ChainPromoters,
    loading,
    allPromoters,
    promoterLoad,
    SupplyChains,
    supplyChainLoad,
  } = useSelector(state => ({
    ChainPromoters: state.SupplyChain.chainPromoters,
    loading: state.SupplyChain.loading,
    allPromoters: state?.Users?.promotersList,
    promoterLoad: state.Users?.promoterLoad,
    SupplyChains: state.SupplyChain.supplyChains,
    supplyChainLoad: state.SupplyChain.loading,
  }))

  const [promoterSelect, setPromoterSelect] = useState({
    title: 'Search Promoter',
  })

  const [supplyChainSelect, setSupplyChainSelect] = useState({
    title: 'Search SupplyChain',
  })

  useEffect(() => {
    if (supplyChainId) {
      setSupplyChainSelect({
        ...supplyChainSelect,
        id: supplyChainId,
      })
    }
  }, [supplyChainId])

  useEffect(() => {
    if (promoterId) {
      setPromoterSelect({
        ...promoterSelect,
        id: promoterId,
      })
    }
  }, [promoterId])

  //call chain promoter
  useEffect(() => {
    if (promoterId) {
      const credentials = {
        page,
        sort: '',
        limit: 10,
        searchText,
        supplyChain: supplyChainSelect?.id || '',
        promoter: promoterId,
      }
      dispatch(getAllChainPromoters(credentials))
    } else if (supplyChainId) {
      const credentials = {
        page,
        sort: '',
        limit: 10,
        searchText,
        supplyChain: supplyChainId,
        promoter: promoterSelect?.id || '',
      }
      dispatch(getAllChainPromoters(credentials))
    } else {
      const credentials = {
        page,
        sort: '',
        limit: 10,
        searchText,
        supplyChain: supplyChainSelect?.id || '',
        promoter: promoterSelect?.id || '',
      }
      dispatch(getAllChainPromoters(credentials))
    }
  }, [
    dispatch,
    page,
    searchText,
    supplyChainSelect,
    promoterSelect,
    promoterId,
    supplyChainId,
  ])

  const totalPages = Math.ceil(ChainPromoters?.total / 10)
  const pages = range(1, totalPages + 1)
  const [isOpen, setIsOpen] = useState({ status: false, id: '' })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
  }

  let columns = [
    {
      dataField: 'ref_id',
      text: 'Ref Id',
      sort: true,
    },
    {
      dataField: 'supplyChainId',
      text: 'Id',
      sort: true,
    },
    {
      dataField: 'promoter',
      text: 'Promoter',
      sort: true,
    },
    {
      dataField: 'chainPosition',
      text: 'Position',
      sort: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Date',
      sort: true,
    },

    {
      dataField: 'action',
      text: 'Action',
    },
  ]

  const handleColumns = () => {
    if (promoterId || isPromoterDashboard) {
      return [
        ...columns?.filter(
          i => i.dataField !== 'promoter' && i.dataField !== 'action',
        ),
      ]
    } else {
      return columns
    }
  }

  const promoterData = map(ChainPromoters?.chainPromoters, item => ({
    ...item,
    supplyChainId: (
      <>
        {isPromoterDashboard ? (
          <p>{item?.supplyChain.chainId}</p>
        ) : (
          <>
            <Link
              to={`/supply-chain/update/${item?.supplyChain?._id}`}
              className="text-info"
              id="supplyChainUpdateId"
            >
              {item?.supplyChain.chainId}
            </Link>
            <UncontrolledTooltip
              placement="bottom"
              target={`supplyChainUpdateId`}
            >
              view more
            </UncontrolledTooltip>
          </>
        )}
      </>
    ),
    promoter: (
      <>
        <Link
          to={`/promoter/${item?.promoter?._id}`}
          className="text-info"
          id="promoterDetailsId"
        >
          {item?.promoter?.name || item?.promoter?.phone}
        </Link>
        <UncontrolledTooltip placement="bottom" target={`promoterDetailsId`}>
          Promoter details
        </UncontrolledTooltip>
      </>
    ),
    product: (
      <div className="d-flex align-items-center ">
        <img
          src={
            item?.supplyChain?.product && item?.supplyChain?.product?.images
              ? item?.supplyChain?.product?.images[0]?.url
              : defaultImg
          }
          alt={'product image'}
          className="avatar-sm me-2 rounded"
        />
        {isPromoterDashboard ? (
          <p>{item?.supplyChain?.product?.primaryLang?.name}</p>
        ) : (
          <>
            <Link
              to={`/product/${item?.supplyChain?.product?._id}`}
              className="text-info"
              id="productDetailsId"
              style={{
                whiteSpace: 'break-spaces',
                width: '200px',
              }}
            >
              {item?.supplyChain?.product?.primaryLang?.name}
            </Link>
            <UncontrolledTooltip placement="bottom" target={`productDetailsId`}>
              Product details
            </UncontrolledTooltip>
          </>
        )}
      </div>
    ),
    chainPosition: (
      <>
        {item?.isDefault ? (
          <Badge className="badge-soft-warning p-1 px-2">Root</Badge>
        ) : (
          <Badge className="badge-soft-success p-1 px-2">
            {item?.chainPosition || 0}
          </Badge>
        )}
      </>
    ),
    action: (
      <>
        <Link to={`/chain-promoter/${item?._id}`} className="text-info">
          <i className="fas fa-eye font-size-15" />
        </Link>
      </>
    ),
    updatedAt: moment(item.createdAt).format('lll'),
  }))

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  //promoterSelect
  const [promoterSearch, setPromoterSearch] = useState('')

  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600,
  )

  useEffect(() => {
    if (!isPromoterDashboard) {
      dispatch(getPromoters(page, promoterSearch))
    }
  }, [dispatch, promoterSearch, isPromoterDashboard])

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const promoter = {
      ...promoterSelect,
      title: event.label,
      id: event.value,
    }
    setPromoterSelect(promoter)
  }

  const promoterOptions = [
    {
      label: 'All Promoters',
      id: '',
    },
    {
      options: (allPromoters?.total >= 1 ? allPromoters?.promoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name || result?.phone,
          value: result?._id,
        }),
      ),
    },
  ]

  //supplyChainSelect
  const [supplyChainSearch, setSupplyChainSearch] = useState('')

  const debounceSupplyChainSearch = debounce(
    value => setSupplyChainSearch(value),
    600,
  )

  useEffect(() => {
    if (!isPromoterDashboard) {
      dispatch(getAllSupplyChains('', 10, supplyChainSearch, ''))
    }
  }, [dispatch, supplyChainSearch, isPromoterDashboard])

  const handleSupplyChainEnters = textEntered => {
    debounceSupplyChainSearch(textEntered)
  }

  function handlerSupplyChainFinalValue(event) {
    const supplyChain = {
      ...supplyChainSelect,
      title: event.label,
      id: event.value,
    }
    setSupplyChainSelect(supplyChain)
  }

  const supplyChainOptions = [
    {
      label: 'none',
      id: '',
    },
    {
      options: (SupplyChains?.total >= 1
        ? SupplyChains?.supplyChains
        : []
      )?.map((result, index) => ({
        key: index,
        label: `${result?.chainId} - ${result?.product?.primaryLang?.name}`,
        value: result?._id,
      })),
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <Col className="col-12">
          <Card style={{ minHeight: '310px' }}>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={'id'}
                columns={handleColumns()}
                data={promoterData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm={isPromoterDashboard ? 8 : 3}>
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                type="text"
                                placeholder="search ref Id"
                                value={searchText}
                                onChange={e =>
                                  debounceProductSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>

                      {!isPromoterDashboard && (
                        <>
                          {promoterId === undefined &&
                            supplyChainId === undefined && (
                              <>
                                <Col sm="6">
                                  <div className="d-flex align-items-center justify-content-start w-100">
                                    <FormGroup className="mb-3 w-100 me-3">
                                      <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                        <Select
                                          onInputChange={handlePromoterEnters}
                                          value={promoterSelect?.title}
                                          placeholder={promoterSelect?.title}
                                          onChange={handlerPromoterFinalValue}
                                          options={promoterOptions}
                                          classNamePrefix="select2-selection"
                                          isLoading={promoterLoad}
                                        />
                                      </div>
                                    </FormGroup>

                                    <FormGroup className="mb-3 w-100">
                                      <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                        <Select
                                          onInputChange={
                                            handleSupplyChainEnters
                                          }
                                          value={supplyChainSelect?.title}
                                          placeholder={supplyChainSelect?.title}
                                          onChange={
                                            handlerSupplyChainFinalValue
                                          }
                                          options={supplyChainOptions}
                                          classNamePrefix="select2-selection"
                                          isLoading={supplyChainLoad}
                                        />
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </>
                            )}
                        </>
                      )}
                      {/* <Col
                        sm="4"
                        className="justify-content-end d-flex align-items-start"
                      >
                       
                      </Col> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {ChainPromoters?.chainPromoters?.length !== 0 ? (
                          <>
                            <Row>
                              <p>Total :&nbsp;{ChainPromoters?.total} </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    key={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {!isPromoterDashboard && (
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            )}
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">
                            No{' '}
                            {role === 'promoter' ? 'sources' : 'ChainPromoters'}{' '}
                            !
                          </p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ChainPromoters

ChainPromoters.propTypes = {
  supplyChainId: PropTypes.string,
  isPromoterDashboard: PropTypes.bool,
  promoterId: PropTypes.string,
}
// noticble
// jjj
