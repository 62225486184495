import { call, put, takeEvery } from 'redux-saga/effects'

// Settings Redux States
import {
  GET_GMAP_PKEY,
  GET_SETTINGS,
  SEARCH_LOCATION,
  UPDATE_SETTINGS,
} from './actionTypes'
import {
  getSettingsSuccess,
  getSettingsFail,
  updateSettingsSuccess,
  updateSettingsFail,
  getMapPublicKeySuccess,
  getMapPublicKeyFail,
  searchPlacesSuccess,
  searchPlacesFail,
  getSettings,
} from './actions'

//Include Both Helper File with needed methods
import { get, ApiPut } from '../../helpers/api_helper'
import { Notification } from '../../components/Common/Notification'

function settingsApi(role) {
  if (role === 'promoter') return get('/settings/promoter')
  else if (role === 'admin') return get('/settings/admin')
}

function updateSettingsApi({ settings, id }) {
  return ApiPut(`/settings/${id}`, settings)
}

function getGmapKeyAPi() {
  return get('/location/googlemap-api-key')
}
function getSearchLocationAPi(query) {
  return get(`/location/search/${query}`)
}

function* fetchSettings({ payload }) {
  try {
    const response = yield call(settingsApi, payload)
    console.log(response?.multiLanguange)
    const multiLanguageString = JSON.stringify(response?.multiLanguange)
    if (response) {
      localStorage.setItem('multiLang', multiLanguageString)
      yield put(getSettingsSuccess(response))
    }
  } catch (error) {
    yield put(getSettingsFail(error))
  }
}

function* onUpdateSettings({ payload }) {
  try {
    const response = yield call(updateSettingsApi, payload)
    yield put(updateSettingsSuccess(response))
    yield put(getSettings('admin'))
    Notification({
      type: 'success',
      message: 'Settings Updated Successfully!',
      title: '',
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: 'error',
        message: error.response?.data?.message,
        title: '',
      })
    }
    yield put(updateSettingsFail(error))
  }
}

function* fetchGmapKey() {
  try {
    const response = yield call(getGmapKeyAPi)
    yield put(getMapPublicKeySuccess(response))
  } catch (error) {
    yield put(getMapPublicKeyFail(error))
  }
}
function* searchPlaces({ payload }) {
  try {
    const response = yield call(getSearchLocationAPi, payload)
    yield put(searchPlacesSuccess(response))
  } catch (error) {
    yield put(searchPlacesFail(error))
  }
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
  yield takeEvery(UPDATE_SETTINGS, onUpdateSettings)
  yield takeEvery(GET_GMAP_PKEY, fetchGmapKey)
  yield takeEvery(SEARCH_LOCATION, searchPlaces)
}

export default settingsSaga
