import { useSelector } from 'react-redux'

export const LanguageSwitch = () => {
  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  const multiLanguange = settings?.multiLanguange

  // Move the logic inside the functional component
  if (multiLanguange) {
    return {
      isMult: multiLanguange?.isMultiLanguage,
      lang1: multiLanguange?.priority,
      lang2: multiLanguange?.secondaryLanguageName,
    }
  } else {
    return {
      isMult: false,
      lang1: multiLanguange?.primaryLanguage,
    }
  }
}
